/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'grid-3x3-gap': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 2v2H2V2zm1 12v-2a1 1 0 00-1-1H2a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1m0-5V7a1 1 0 00-1-1H2a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1m0-5V2a1 1 0 00-1-1H2a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1m5 10v-2a1 1 0 00-1-1H7a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1m0-5V7a1 1 0 00-1-1H7a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1m0-5V2a1 1 0 00-1-1H7a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1M9 2v2H7V2zm5 0v2h-2V2zM4 7v2H2V7zm5 0v2H7V7zm5 0h-2v2h2zM4 12v2H2v-2zm5 0v2H7v-2zm5 0v2h-2v-2zM12 1a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1V2a1 1 0 00-1-1zm-1 6a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1zm1 4a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 00-1-1z"/>',
    },
});
